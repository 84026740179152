const Reducer = (state, { type, payload }) => {
    if (type === "SET_CURRRENT_CUSTOMERS_INPUT") {
      return {
        ...state,
        currentCustomers: payload,
      };
    }
    if (type === "SET_NEW_CUSTOMERS_INPUT") {
      return {
        ...state,
        newCustomers: payload,
      };
    }
    if (type === "SET_CHURN_RATE_INPUT") {
      return {
        ...state,
        churnRate: payload,
      };
    }
    if (type === "SET_ARPU_INPUT") {
      return {
        ...state,
        arpu: payload,
      };
    }
  
    if (type === "SET_PLAN_INPUT") {
      return {
        ...state,
        plan: payload,
      };
    }

    if (type === "SET_PER_MB") {
      return {
        ...state,
        perMB: payload,
      };
    }

    if (type === "SET_PER_COMPUTE") {
      return {
        ...state,
        perCompute: payload,
      };
    }
  
    if (type === "SET_OCTY_TOTAL_FEE_OUTPUT") {
      return {
        ...state,
        octyTotalFeeOutput: payload,
      };
    }
    if (type === "SET_OCTY_SUB_FEE_OUTPUT") {
      return {
        ...state,
        octySubscriptionFeeOutput: payload,
      };
    }
    if (type === "SET_OCTY_DATA_FEE_OUTPUT") {
      return {
        ...state,
        octyDataFeeOutput: payload,
      };
    }
    if (type === "SET_OCTY_COMPUTE_FEE_OUTPUT") {
      return {
        ...state,
        octyComputeFeeOutput: payload,
      };
    }
  
    if (type === "SET_CHURN_RATE_OUTPUT") {
      return {
        ...state,
        churnRateOutput: payload,
      };
    }
  
    if (type === "SET_REVENUE_WITH_OCTY_OUTPUT") {
      return {
        ...state,
        revenueTwelveMonthsWithOctyOutput: payload,
      };
    }

    if (type === "SET_REVENUE_WITHOUT_OCTY_OUTPUT") {
      return {
        ...state,
        revenueTwelveMonthsWithoutOctyOutput: payload,
      };
    }

    if (type === "SET_REVENUE_DIFF_OUTPUT") {
      return {
        ...state,
        revenueDifferenceOutput: payload,
      };
    }

    if (type === "SET_REVENUE_DIFF_PER_OUTPUT") {
      return {
        ...state,
        revenueDifferencePercentageOutput: payload,
      };
    }

    if (type === "SET_OCTY_ROI_OUTPUT") {
      return {
        ...state,
        octyROIOutput: payload,
      };
    }
  
    if (type === "RESET") {
      return {
        ...state,
        plan: 499,
        currentCustomers: 0,
        newCustomers: 0,
        churnRate: 0,
        arpu: 0,
        perMB : 0.03,
        perCompute : 1.34,
        octySubscriptionFeeOutput : 499,
        octyDataFeeOutput : 0,
        octyComputeFeeOutput : 0,
        octyTotalFeeOutput : 0,
        churnRateOutput: 0,
        revenueTwelveMonthsWithOctyOutput: 0,
        revenueTwelveMonthsWithoutOctyOutput: 0,
        revenueDifferenceOutput: 0,
        revenueDifferencePercentageOutput: 0,
        octyROIOutput: 0,
      };
    }
  
    throw new Error("no matching action type: ");
  };
  
  export default Reducer;
  