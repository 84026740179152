import React from "react";

const socialContent = [
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/company/69074766",
  },
];

const CopyRight = () => {
  return (
    <div className="row">
      <div className="col-lg-4 order-lg-1 mb-20">
        <ul
          className="
              d-flex
              justify-content-center justify-content-lg-start
              footer-nav
            "
        >
          {/* <li>
            <Link to="/">GDPR</Link>
          </li> */}
          <li>
            <a href="https://legals.octy.ai/Octy-Data-Processing-Addendum-64556d89ec714ca5ac6852e663eadb51" target="_blank">Data Addendum</a>
          </li>
          <li>
            <a href="https://legals.octy.ai/Octy-Security-Policy-4886847a6167463da3ffb565dbe99fb8" target="_blank">Octy Security Policy</a>
          </li>
        </ul>
      </div>
      <div className="col-lg-4 order-lg-3 mb-20">
        <ul
          className=" d-flex
              justify-content-center justify-content-lg-end
              social-icon"
        >
          {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`}></i>
              </a>
            </li>
          ))}
        </ul>
        {/* End .social-icon */}
      </div>
      <div className="col-lg-4 order-lg-2 mb-20">
        <p className="copyright text-center">
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="https://octy.ai"
            target="_blank"
            rel="noopener noreferrer"
          >
            AQB Net
          </a>{" "}
          Inc.
        </p>
      </div>
    </div>
  );
};

export default CopyRight;
