import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const PartnerPerksContent = [
  {
    title: "All partners welcome!",
    desc: `Whether you’re a tech whiz, agency or affiliate busybee, sponsor, investor or partner at heart, we want to hear from you. We partner with different teams for different reasons and objectives. No matter your desired relation with us, bring additional value to your product offering or portfolio with the power of Octy AI.`,
    expand: "a",
  },
  {
    title: "As the customer grows, so does your commission",
    desc: `Earn a 10% commission for any customers you refer to Octy AI. Any time they upgrade to a higher tier, you'll receive commission on the updated cost. Additionally, get compensated for any new users your referral refers.`,
    expand: "b",
  },
  {
    title: "Get paid on time, every time",
    desc: `We make getting paid easy. With Octy AI you won't have to chase down your affiliate payments. We pay out on every referred customer that has an active Octy AI account. Always on time.`,
    expand: "c",
  },
  {
    title: "Collateral that enables sales",
    desc: `All Octy AI Partners get access to partner-specific marketing assets to use in campaigns. Our team always has your back and is happy to provide assistance with any product, billing, or marketing questions you may have. — We even provide technical consultancy to our partners to make sure they know their stuff when it comes to Octy AI.`,
    expand: "d",
  },
];

const PartnerPerkItems = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {PartnerPerksContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default PartnerPerkItems;
