import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TeamDetailsSlider() {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    adaptiveHeight: true,
  };

  const SliderContent1 = [
    {
      bigImage: "Rairo",
      name: "Rairo Mukamuri",
      designation: "Software Engineer | Machine Learning",
      aboutDetails: `I am an experienced technologist and software developer with a passion for AI. `,
      SoftExperience: `
[Software Engineering]:: 
⇒ Python/Java/R
⇒ Native Android/React Native
⇒ JavaScript/HTML/CSS
⇒ Node/Express/Flask/Django
⇒ Scikit-Learn/Pytorch/Sagemaker
⇒ Data Analysis/Machine Learning
⇒ SQL/MongoDB/PostgreSQL/Firebase
⇒ Electron/Vue
⇒ Git/Netlify/Contentful
`,
      BizExperience: `
[Awards]::
⇒ First place award for the 2020 Facebook Developer Circles Community Challenge
⇒ 2020 HCL pinkdata award for
best use of data and analytics
to raise breast cancer
awareness
⇒ 2015 YETT Social Entrepreneurship award for ICT 
      `,
      social: [
        {
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/rairo-mukamuri/",
        },
      ],
    }
  ];


  const SliderContent4 = [
    {
      bigImage: "henning",
      name: "Henning Dekant",
      designation: "Co-Founder/CEO",
      aboutDetails: `My goal is to elevate your IT capabilites to the next level. `,
      SoftExperience: `
[Software Engineering]:: 
⇒ Machine Learning
⇒ Python
⇒ Node.JS/Javascript
⇒ Quantum Computing
⇒ Kubernetes
⇒ Docker
⇒ AWS
⇒ C++
`,
      BizExperience: `
[Business]::
⇒ Martech
⇒ Customer Retention and Interaction Management 
⇒ Balanced Scorecard
⇒ Business Intelligence 
⇒ Startup Bootstrapping
      `,
      social: [
        {
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/henningdekant/",
        },
      ],
    }
  ];


  const SliderContent2 = [
    {
      bigImage: "ben",
      name: "Ben Goodenough",
      designation: "Co-Founder",
      aboutDetails: `I am an ambitious and experienced entrepreneur and full-stack Python engineer with a proven track record of success. I've brought value to numerous companies and have raised money for various projects. I'm a huge believer in artificial intelligence, or AI, and its impact on different industries, society, and everyday life. I'm excited to be involved in and continue learning more about these advancements. `,
      SoftExperience: `
[Software Engineering]:: 
⇒ AI/ML
⇒ Python
⇒ Golang
⇒ Javascript
⇒ Swift
⇒ Data scraping/aggregation
⇒ Kubernetes
⇒ Docker
⇒ DevOps
`,
      BizExperience: `
[Business]::
⇒ Value chain analysis
⇒ Activity system analysis 
⇒ Business strategy conceptualisation and auditing
⇒ Lean Business Modelling
⇒ BizOps
      `,
      social: [
        {
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/ben--goodenough/",
        },
      ],
    }    
  ];

  const SliderContent3 = [
    {
      bigImage: "Scott",
      name: "Scott Horlacher",
      designation: "Co-Founder",
      aboutDetails: `I am a full-stack developer, ghost-writer and all-around creative person. I graduated in Electrical&Biomedical Engineering with a passion for all things Javascript. I have hands-on experience with IoT and Ethereum, and I am fluent in Javascript frameworks, with a keen interests in data-science and distributed computing.  `,
      SoftExperience: `
[Software Engineering]:: 
⇒ Node.JS/Javascript
⇒ Python
⇒ Data scraping with Selenium
⇒ Kubernetes
⇒ Docker
⇒ DevOps
⇒ AWS
`,
      BizExperience: `
[Business]::
⇒ IT Patent strategy 
⇒ Business Requirement Analysis 
⇒ Start-Up Networking

      `,
      social: [
        {
          icon: "fa fa-linkedin",
          link: "https://www.linkedin.com/in/scott-horlacher-0b5607164/",
        },
      ],
    }    
  ];

  return (
    <><Slider {...settings}>
      {SliderContent4.map((val, i) => (
        <div className="main-bg d-lg-flex align-items-center" key={i}>
          <div className="img-meta">
            <img
              src={`images/media/${val.bigImage}.png`}
              alt="octy team"
              className="w-100" />
          </div>
          {/* End img-meta */}

          <div className="text-wrapper">
            <div className="name font-source-code-pro">{val.name}</div>
            <div className="position">{val.designation}</div>
            <h6 className="font-source-code-pro">ABOUT ME</h6>
            <p className="pb-45">{val.aboutDetails}</p>
            <h6 className="font-source-code-pro">Experties</h6>
            <p className="pb-45">{val.SoftExperience}</p>
            <p className="pb-45">{val.BizExperience}</p>
            <h6 className="font-source-code-pro">FOLLOW & CONTACT</h6>
            <ul className="social-icon d-flex pt-15">
              {val.social.map((social, i) => (
                <li key={i}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    <i className={social.icon}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* /.text-wrapper */}
        </div>
      ))}
    </Slider>
    
    <Slider {...settings}>
      {SliderContent1.map((val, i) => (
        <div className="main-bg d-lg-flex align-items-center" key={i}>
          <div className="img-meta">
            <img
              src={`images/media/${val.bigImage}.png`}
              alt="octy team"
              className="w-100" />
          </div>
          {/* End img-meta */}

          <div className="text-wrapper">
            <div className="name font-source-code-pro">{val.name}</div>
            <div className="position">{val.designation}</div>
            <h6 className="font-source-code-pro">ABOUT ME</h6>
            <p className="pb-45">{val.aboutDetails}</p>
            <h6 className="font-source-code-pro">Experties</h6>
            <p className="pb-45">{val.SoftExperience}</p>
            <p className="pb-45">{val.BizExperience}</p>
            <h6 className="font-source-code-pro">FOLLOW & CONTACT</h6>
            <ul className="social-icon d-flex pt-15">
              {val.social.map((social, i) => (
                <li key={i}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    <i className={social.icon}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* /.text-wrapper */}
        </div>
      ))}
    </Slider>

    <Slider {...settings}>
    {SliderContent3.map((val, i) => (
        <div className="main-bg d-lg-flex align-items-center" key={i}>
          <div className="img-meta">
            <img
              src={`images/media/${val.bigImage}.png`}
              alt="octy team"
              className="w-100" />
          </div>
          {/* End img-meta */}

          <div className="text-wrapper">
            <div className="name font-source-code-pro">{val.name}</div>
            <div className="position">{val.designation}</div>
            <h6 className="font-source-code-pro">ABOUT ME</h6>
            <p className="pb-45">{val.aboutDetails}</p>
            <h6 className="font-source-code-pro">Experties</h6>
            <p className="pb-45">{val.SoftExperience}</p>
            <p className="pb-45">{val.BizExperience}</p>
            <h6 className="font-source-code-pro">FOLLOW & CONTACT</h6>
            <ul className="social-icon d-flex pt-15">
              {val.social.map((social, i) => (
                <li key={i}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    <i className={social.icon}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* /.text-wrapper */}
        </div>
      ))} 
          
      </Slider>
    <Slider {...settings}>
    {SliderContent2.map((val, i) => (
        <div className="main-bg d-lg-flex align-items-center" key={i}>
          <div className="img-meta">
            <img
              src={`images/media/${val.bigImage}.png`}
              alt="octy team"
              className="w-100" />
          </div>
          {/* End img-meta */}

          <div className="text-wrapper">
            <div className="name font-source-code-pro">{val.name}</div>
            <div className="position">{val.designation}</div>
            <h6 className="font-source-code-pro">ABOUT ME</h6>
            <p className="pb-45">{val.aboutDetails}</p>
            <h6 className="font-source-code-pro">Experties</h6>
            <p className="pb-45">{val.SoftExperience}</p>
            <p className="pb-45">{val.BizExperience}</p>
            <h6 className="font-source-code-pro">FOLLOW & CONTACT</h6>
            <ul className="social-icon d-flex pt-15">
              {val.social.map((social, i) => (
                <li key={i}>
                  <a href={social.link} target="_blank" rel="noreferrer">
                    <i className={social.icon}></i>
                  </a>
                </li>
              ))}
            </ul>
          </div>
          {/* /.text-wrapper */}
        </div>
      ))} 
      
      </Slider></>
  );
}
