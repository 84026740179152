import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { Link } from "react-router-dom";

const Header = () => {
  // For Mobile  menu
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 90) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      {/* =============================================
				Theme Main Menu
			==============================================  */}
      <div
        className={
          navbar
            ? "theme-main-menu sticky-menu theme-menu-seven bg-none fixed"
            : "theme-main-menu sticky-menu theme-menu-seven bg-none"
        }
      >
        <div className="d-flex align-items-center justify-content-between">
          <div className="logo order-lg-1">
            <Link to="/">
              <img src="images/logo/no_text_trans@2x.png" alt="Octy AI logo" className="main-logo"/>
            </Link>
          </div>
          {/* End logo */}

          <div className="right-widget order-lg-3">
            <ul className="d-flex align-items-center">
              <li>
                <a className="signIn-action d-flex align-items-center" href="https://docs.octy.ai/" target="_blank">                  
                  <img src="images/icon/122.svg" alt="developer documentation icon" />
                  <span>Docs</span>
                  </a>
              </li>

              <li>
                <Link
                  to="/book-a-call"
                  className="theme-btn-twelve d-flex"
                >Book a 🤙</Link>
              </li>
            </ul>
          </div>
          {/* End right-widget */}

          <nav
            id="mega-menu-holder"
            className="navbar navbar-expand-lg pl-xl-5 ml-xl-5 order-lg-2"
          >
            <div className="container nav-container">
              <div className="mob-header">
                <button className="toggler-menu" onClick={handleClick}>
                  <div className={click ? "active" : ""}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </button>
              </div>
              {/* End Header */}

              <div
                className="navbar-collapse collapse landing-menu-onepage"
                id="navbarSupportedContent"
              >
                <div className="d-lg-flex justify-content-between align-items-center">
                  <Scrollspy
                    className="navbar-nav  main-side-nav font-source-code-pro"
                    items={[]}
                    currentClassName="active"
                    offset={-90}
                  >
                    <li className="nav-item">
                      <Link className="nav-link" to="/">Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/about">About</Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/pricing">Pricing</Link>
                    </li>
                    <li className="nav-item">
                    <a href="https://helpcenter.octy.ai" className="nav-link" target="_blank">Help Center</a>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/partnership">Partnership</Link>
                    </li>
                  </Scrollspy>
                </div>
              </div>
            </div>
          </nav>
          {/* End Navbar */}
        </div>
      </div>
      {/* /.theme-main-menu */}

      {/* Mobile Menu Start */}
      <div className={click ? "mobile-menu  menu-open" : "mobile-menu"}>
        <div className="logo order-md-1">
          <Link to="/doc-landing">
            <img src="images/logo/text_under_trans@2x.png" alt="Octy AI logo" className="main-logo"/>
          </Link>
          <div className="fix-icon text-dark" onClick={handleClick}>
            <img src="images/icon/close.svg" alt="close icon" />
          </div>
          {/* Mobile Menu close icon */}
        </div>

        <Scrollspy
          className="navbar-nav  main-side-nav font-source-code-pro"
          items={[]}
          currentClassName="active"
          offset={-90}
        >
          <li className="nav-item">
            <Link className="nav-link" to="/">Home</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/about">About</Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/pricing">Pricing</Link>
          </li>
          <li className="nav-item">
            <a href="https://helpcenter.octy.ai" className="nav-link" target="_blank">Help Center</a>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/partnership">Partnership</Link>
          </li>
        </Scrollspy>
      </div>
      {/* Mobile Menu End */}
    </>
  );
};

export default Header;
