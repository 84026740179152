import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const PartnershipTabs = () => {
  return (
    <>
      <Tabs>
        <TabList className="nav nav-tabs md-mt-50" style={ {color : "black" } }>
          <Tab className="nav-link font-source-code-pro">Co-Marketing</Tab>
          <Tab className="nav-link font-source-code-pro">Octy Partner program</Tab>
          <Tab className="nav-link font-source-code-pro">Integration partners</Tab>
        </TabList>
        {/* End TabList */}

        <div className="tab-content mt-60 mb-40" style={ {color : "black" } }>
          <TabPanel>
            <p className="font-source-code-pro pb-30">
            If you are interested in co-marketing, please use the form (link below) for a formal marketing opportunity to partner with us.
            </p>
            <a href="https://forms.gle/E2mjpVdTqYAQWHCc6" target="_blank" className="theme-btn-seven">
              Request co-marketing opportunity
            </a>
          </TabPanel>
          <TabPanel>
          <p className="font-source-code-pro pb-30">
            Partner with us and get paid a commission every time you refer a customer. Earn up to 10% commission on each onboarded account.
            </p>
            <a href="https://forms.gle/botF3aW7qmyGsZHMA" target="_blank" className="theme-btn-seven">
              Join the partner programme
            </a>
          </TabPanel>
          <TabPanel>
            <p className="font-source-code-pro pb-30">
            Want to integrate with Octy AI – great we love innovation. Reach out to us to discuss white label opportunities. In the mean time, read the docs to see what possible.
            </p>
            <p className="font-source-code-pro pb-30">
            support@octy.ai
            </p>
            <a href="https://docs.octy.ai" target="_blank" className="theme-btn-seven">
              Read the Docs
            </a>
          </TabPanel>
        </div>
        {/* End TabContent */}
      </Tabs>
    </>
  );
};

export default PartnershipTabs;
