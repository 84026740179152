import React from "react";

const featureContent = [
  {
    delayAnimation: "0",
    colorOfBorder: "#FFCE22",
    icon: "segments-icon",
    title: "Customer segmentation engine",
    description: `Our Customer segmentation engine will allow you to hyper-target your messaging and offers which means that your rapport with your most valued customers will increase dramatically.`,
  },
  {
    delayAnimation: "150",
    colorOfBorder: "#8F6BF6",
    icon: "recommendations-icon",
    title: "Recommendations system",
    description: `Our recommendation system provides your customers with an omnichannel personalized experience, which means a greater likelihood of repeat visits. You can show your customers the right products at the right moments.`,
  },
  {
    delayAnimation: "0",
    colorOfBorder: "#FF5C5C",
    icon: "nlp-icon",
    title: "Natural language generation engine",
    description: `Our natural language generation engine allows you to generate personalised messages and content, as well as dynamic website sections, easily, which means you can communicate with all of your customers on ‘first-name-basis’ and dramatically improve your conversion rate in every touch point.`,
  },
  {
    delayAnimation: "150",
    colorOfBorder: "#63EAA9",
    icon: "churn-icon",
    title: "Churn prediction analysis",
    description: `Automated churn prediction analysis allows you to understand the critical consumer attributes and behaviours that contribute to customer churn, enabling you to hyper-focus your actions and customer interactions to reduce your churn rate.`,
  },
  {
    delayAnimation: "",
    colorOfBorder: "#5BE2FF",
    icon: "rfm-icon",
    title: "RFM (recency, frequency, monetary) analysis",
    description: `Using our Machine Learning and data mining technology, we can detect your most valuable customers and highlight them in an automated Recency, Frequency and Monetary analysis. This means that you can focus your budgets and efforts on serving them, giving you a significant increase in your ‘customer retention’ budget ROI.`,
  },
  {
    delayAnimation: "150",
    colorOfBorder: "#FF56EE",
    icon: "identify-icon",
    title: "Customer profile identification",
    description: `Customer profile identification tracks your customer’s sessions on multiple devices and automatically combines their behavioural data to formulate a more comprehensive view of each customer.`,
  },
];

const RetentionToolchainFeatures = () => {
  return (
    <div className="row">
      {featureContent.map((val, i) => (
        <div
          className="col-lg-6"
          data-aos="fade-up"
          data-aos-delay={val.delayAnimation}
          key={i}
        >
          <div
            className="block-style-nineteen"
            style={{ borderLeftColor: val.colorOfBorder }}
          >
            <div className="d-flex align-items-start">
              <img
                src={`images/icon/${val.icon}.svg`}
                alt="octy feature icon"
                className="icon"
              />
              <div className="text">
                <h4>{val.title}</h4>
                <p>{val.description}</p>
              </div>
            </div>
          </div>
          {/* /.block-style-nineteen */}
        </div>
      ))}

      {/* End .col */}
    </div>
  );
};

export default RetentionToolchainFeatures;
