import React from "react";
import { Link } from "react-router-dom";

const marketersFeatureList = [
    {
      icon: "intelligence-icon",
      title: "",
      description: `Design and launch automated and intelligent retention campaigns.`,
    },
    {
      icon: "omnichannel-icon",
      title: "",
      description: `Create a hyper-personalized omnichannel experience for your customers.`,
    },
    {
      icon: "target-icon",
      title: "",
      description: `Identify and target your best, most likely to churn customers.`,
    },
  ];
  
  const developersFeatureList = [
    {
      icon: "docs-icon",
      title: "",
      description: `Well-written developer documentation with demo integrations for your reference.`,
    },
    {
      icon: "command-line-icon",
      title: "",
      description: `A command-line tool for simplified account and resource management.`,
    },
    {
      icon: "plug-icon",
      title: "",
      description: `No AI experience needed. Just plug and play — simple to integrate with your current stack.`,
    },
  ];

const ForMarketersDevelopers = () => {
  return (
        <div className="container">

        <div className="block-style-twentyThree">
        <div className="row align-items-center">
            <div
            className="col-lg-6 order-lg-last ml-auto"
            data-aos="fade-left"
            data-aos-duration="300"
            >
            <div className="screen-container ml-auto">
                <div
                className="oval-shape"
                style={{ background: "#FFF170" }}
                ></div>
                <div
                className="oval-shape"
                style={{ background: "#69FF9C" }}
                ></div>
                <img
                src="images/assets/marketers.png"
                alt="cloud computer with graphs"
                className="shapes shape-one"
                />

            </div>
            {/* /.screen-container */}
            </div>
            <div
            className="col-lg-5 order-lg-first"
            data-aos="fade-right"
            data-aos-duration="600"
            >
            <div className="text-wrapper">
                <h6>Grow</h6>
                <h3 className="title">Octy AI for marketers</h3>
                <ul className="feature-list-one">
                {marketersFeatureList.map((list, i) => (
                  <li key={i}>
                    <img
                      src={`images/icon/${list.icon}.svg`}
                      alt="marketing feature icon"
                      className="icon"
                      style={{ height: 80, width: 50 }}
                    />
                    <strong>{list.title}</strong>
                    <span>{list.description}</span>
                  </li>
                ))}
              </ul>

              <Link to="/for-marketers" className="theme-btn-thirteen mt-20">Learn More</Link>
            </div>
            {/*  /.text-wrapper */}
            </div>
        </div>
        </div>
        {/* /.block-style-twentyThree */}

        <div className="block-style-twentyThree">
        <div className="row">
            <div className="col-lg-6">
            <div
                className="screen-container mr-auto"
                data-aos="fade-right"
                data-aos-duration="600"
            >
                <div
                className="oval-shape"
                style={{ background: "#FFDE69" }}
                ></div>
                <div
                className="oval-shape"
                style={{ background: "#FF77D9" }}
                ></div>
                <img
                src="images/assets/developers.png"
                alt="laptop with code on screen"
                className="shapes shape-two"
                />
            </div>
            {/* /.screen-container */}
            </div>
            <div
            className="col-lg-5 ml-auto"
            data-aos="fade-left"
            data-aos-duration="600"
            >
            <div className="text-wrapper">
                <h6>Build</h6>
                <h3 className="title">Octy AI for developers</h3>
                <ul className="feature-list-one">
                {developersFeatureList.map((list, i) => (
                  <li key={i}>
                    <img
                      src={`images/icon/${list.icon}.svg`}
                      alt="octy developer feature icon"
                      className="icon"
                      style={{ height: 80, width: 50 }}
                    />
                    <strong>{list.title}</strong>
                    <span>{list.description}</span>
                  </li>
                ))}
              </ul>
              <Link to="/for-developers" className="theme-btn-thirteen mt-20">Learn More</Link>
            </div>
            {/* /.text-wrapper */}
            </div>
        </div>
        </div>
        {/* /.block-style-twentyThree */}

        </div>
  );
};

export default ForMarketersDevelopers;