import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import PartnerPerks from "../../../../components/partner-perks/PartnerPerks";
import PartnershipTabs from "../../../../components/block-slider/PartnershipTabs";
import CallToAction from "../../../../components/call-to-action/CallToAction";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";

const PartnershipPage = () => {
  return (
    <>

      <Helmet>
        <title>
          {" "}
          Partnerships || Octy AI
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header/>
      {/* End Header */}

      {/* =============================================
				Hero Banner
			============================================== */}
      <div className="pricing-section-one mb-40 md-mb-80">
      <div className="hero-banner-twelve">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-8 col-sm-10 pb-200">
                <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
                Promote game-changing AI tools for SMB and enterprise customers.
                </h1>
                <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
                >
                   Become our partner and earn a 10% recurring commission on all new paying customers you refer. 🤝
                </p>
                <div data-aos="fade-up" data-aos-delay="200">
                <a href="https://forms.gle/botF3aW7qmyGsZHMA" className="get-start-btn" target="_blank">
                Join the partner program{" "}
                <i className="fa fa-angle-right ml-1" aria-hidden="true"></i>
              </a>
            </div>
              </div>

            </div>
                <img
                src="images/shape/233.svg"
                alt="shape"
                className="shapes shape-four"
                />
                <img
                src="images/shape/234.svg"
                alt="shape"
                className="shapes shape-five"
                />
                <img
                src="images/shape/235.svg"
                alt="shape"
                className="shapes shape-six"
                />
                <img
                src="images/shape/236.svg"
                alt="shape"
                className="shapes shape-seven"
                />
                <img
                src="images/shape/232.svg"
                alt="shape"
                className="shapes shape-eight"
                />
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-five */}
      </div>
      {/* End Hero Banner */}

      {/* =============================================
				Partner Success
		  ============================================== */}
        <PartnerPerks/>
      {/* End Partner Success */}
      
      {/* =============================================
				How to partner with Octy AI
			============================================== */}
      <div className="fancy-text-block-fifteen" style={ {paddingBottom: "80px"} }>
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container" style={{maxWidth : "85%"}}>
          <div className="bg-wrapper" style={ {backgroundColor: '#fcd305'} }>
            <div className="row">
            <div
                className="col-lg-8 ml-auto order-lg-last"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <PartnershipTabs />
              </div>
              {/* End .col */}

              <div
                className="col-lg-4 order-lg-first"
                data-aos="fade-up"
                data-aos-duration="600"
              >
            <div className="title-style-one">
              <h2>
              Let's get this partnership started!
              </h2>
              <img 
                src="images/assets/party.png" 
                className="main-img"
                />
            </div>
              </div>
              {/* End .col */}
            </div>
          </div>
          {/* /.bg-wrapper */}
        </div>
      </div>
      {/* End How to partner with Octy AI  */}

      {/* =====================================================
           CTA
      ===================================================== */}
      <CallToAction />
      {/* End CTA */}

      {/* =====================================================
        Footer
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer/>
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}


    </>
  );
};

export default PartnershipPage;
