import React from "react";
import { Link } from "react-router-dom";
import FormFooterSignup from "../form/FormFooterSignup";

const Footer = () => {
  return (


    <div className="row ">
      <div
        className="col-xl-3 col-lg-2 mb-40"
        data-aos="fade-up"
        data-aos-duration="600"
      >
        <div className="logo">
          <a href="/">
          <img src="images/logo/text_side_trans@2x.png" alt="Octy AI logo" style={{ width: "100%" }}/>
          </a>
        </div>
      </div>
      {/* End .col */}

      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="600"
        data-aos-delay="100"
      >
        <h5 className="title">Company</h5>
        <ul className="footer-list">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/partnership">Partnership</Link>
          </li>
          <li>
            <a href="https://legals.octy.ai/Octy-Acceptable-Use-Privacy-Policy-1b393284b0454184bd81aeec8cd79870" target="_blank">Acceptable Use Policy</a>
          </li>
        </ul>
      </div>

      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="600"
        data-aos-delay="150"
      >
        <h5 className="title">Help & Support</h5>
        <ul className="footer-list">
          <li>
          <a href="https://helpcenter.octy.ai" target="_blank">Help Center</a>
          </li>
          <li>
            <Link to="/book-a-call">Contact Sales</Link>
          </li>
          <li>
            <a href="https://docs.octy.ai/" target="_blank">Documentation</a>
          </li>
        </ul>
      </div>
      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="600"
        data-aos-delay="150"
      >
        <h5 className="title">Developers</h5>
        <ul className="footer-list">
          <li>
            <a href="https://api-docs.octy.ai/" target="_blank">API reference</a>
          </li>
          <li>
            <a href="https://github.com/Octy-ai/octy-cli/releases/latest" target="_blank">Octy CLI</a>
          </li>
          <li>
            <br/>
          <div class="postman-run-button"
                                    data-postman-action="collection/fork"
                                    data-postman-var-1="16081408-e687f162-c2f1-499f-b0dc-7de8495b92d3"
                                    data-postman-collection-url="entityId=16081408-e687f162-c2f1-499f-b0dc-7de8495b92d3&entityType=collection&workspaceId=91665a6b-e719-4332-ba4c-be74a8d6daa4"></div>
          {
            (function (p,o,s,t,m,a,n) {
              !p[s] && (p[s] = function () { (p[t] || (p[t] = [])).push(arguments); });
              !o.getElementById(s+t) && o.getElementsByTagName("head")[0].appendChild((
                (n = o.createElement("script")),
                (n.id = s+t), (n.async = 1), (n.src = m), n
              ));
            }(window, document, "_pm", "PostmanRunObject", "https://run.pstmn.io/button.js"))
          }
          </li>
        </ul>
      </div>

      <div
        className="col-lg-2 col-md-6 mb-40"
        data-aos="fade-up"
        data-aos-duration="600"
        data-aos-delay="150"
      >
        <h5 className="title">Products</h5>
        <ul className="footer-list">
          <li>
            <Link to="/">Retention Toolchain</Link>
          </li>
          <li>
            <Link to="/changelog">Changelog</Link>
          </li>
          <li>
            <a target="_blank" href="https://octy-ai.notion.site/83b965a2fda546f9a899fc6ea926b18f?v=2928b016f1d04615ac00402b78b8e4f0">Product roadmap</a>
          </li>
        </ul>
      </div>
      
      {/* <div
        className="col-xl-4 col-lg-5 mb-40"
        data-aos="fade-up"
        data-aos-duration="600"
        data-aos-delay="200"
      >
        <div className="newsletter">
          <h5 className="title">Newsletter</h5>
          <p>
            Join over <span>10,000</span> people getting our emails each month.
          </p>

          <FormFooterSignup />

          <div className="info">
            No spam. Unsubscribe at any time.
          </div>
        </div>
        
      </div> */}
      {/* /.newsletter */}
    </div>
  );
};

export default Footer;
