import React from 'react';
import PartnerPerkItems from "./PartnerPerkItems";

const PartnerPerks = () => {
  return (
    <div className="fancy-feature-twenty mt-40 md-mt-10 pb-100">
    <div className="wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="title-style-one">
            <h2>The (many) perks of becoming an Octy Partner</h2>
          </div>
          {/* /.title-style-one */}
        </div>
        {/* End .col */}
        <div className="col-lg-6">
          <div className="md-mt-60">
            <PartnerPerkItems />
          </div>
        </div>
        {/* End .col */}
      </div>

    </div>
    </div>
    {/* <!-- /.wrapper --> */}
  </div>

  );
};

export default PartnerPerks;