import React from "react";

const BrandImages = [
  "shopify-logo",
  "magento-logo",
  "bigcommerce-logo",
  "3dcart-logo",
  "salesforce-commerce-cloud-logo",
  "manymore-logo-g",
  "woocommerce-logo",
];

const Brands = () => {
  return (
    <>
      {BrandImages.map((val, i) => (
        <div
          className="logo d-flex align-items-center justify-content-center"
          key={i}
        >
          <img src={`images/logo/${val}.png`} alt="ecommerce brand logo" />
        </div>
      ))}
    </>
  );
};

export default Brands;
