import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Calendly from "../../../../components/calendar/Calendly";
import CustomerSuccess from "../../../../components/customer-success/CustomerSuccess";
import CallToAction from "../../../../components/call-to-action/CallToAction";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";

const ContactPage = () => {
  return (
    <>
      <Helmet>
        <title>
          Book a Call || Octy AI
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header/>
      {/* End Header */}

      {/* =============================================
				Hero banner
			============================================== */}
      <div className="hero-banner-twelve">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-10">
                <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
                Talk to our customer success team
                </h1>
                <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
                >
                   Find out if your business is a good fit for Octy in less than 10 minutes. No pushy sales calls. Use the calendar widget to book a call on a date and time that is best for you.
                </p>
                <div data-aos="fade-up" data-aos-delay="200">
            </div>
              </div>
              <div className="col-lg-5 col-md-4">
                <Calendly/>
              </div>
            </div>
                <img
                src="images/shape/233.svg"
                alt="shape"
                className="shapes shape-four"
                />
                <img
                src="images/shape/234.svg"
                alt="shape"
                className="shapes shape-five"
                />
                <img
                src="images/shape/235.svg"
                alt="shape"
                className="shapes shape-six"
                />
                <img
                src="images/shape/236.svg"
                alt="shape"
                className="shapes shape-seven"
                />
                <img
                src="images/shape/232.svg"
                alt="shape"
                className="shapes shape-eight"
                />
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* Hero banner */}

      {/* 
      =============================================
				CustomerSuccess
		  ============================================== */}
        <CustomerSuccess/>
      {/* End Customer Success */}

      {/* =====================================================
           CTA
      ===================================================== */}
      <CallToAction />
      {/* End CTA */}

      {/* =====================================================
        Footer
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer/>
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}


    </>
  );
};

export default ContactPage;
