import React from "react";

const ARPU = ({ arpu, onARPUChange }) => {

  const handleARPUInput = (event) => {
    onARPUChange(event.target.value);
  };
  return (
    <>
      <label htmlFor="a">Average revenue per customer £ (Monthly)</label>
      <br/>
      <input
        autoComplete="off"
        className="input"
        type="number"
        id="a"
        name="a"
        value={arpu}
        placeholder="£0"
        onChange={handleARPUInput}
        required
      />
      <br/>
    </>
  );
};

export default ARPU;