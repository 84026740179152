import React from "react";
import { Link } from "react-router-dom";

const CallToAction = () => {
  return (
    <div className="fancy-short-banner-fourteen mt-100 md-mt-80"> {/*mt-150*/}
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-30" data-aos="fade-up" data-aos-duration="600">
            <div className="title-style-thirteen">
              <h2>
                Octy AI Docs
              </h2>
            </div>
            <p>Get to know Octy AI by reading our awesome developer documentation.</p>
            <a href="https://docs.octy.ai/" target="_blank" className="theme-btn-seven">
              Read the Docs 📖
            </a>
          </div>
          <div className="col-lg-6" data-aos="fade-up" data-aos-duration="600">
            <div className="title-style-thirteen">
              <h2>
              Lets talk
              </h2>
            </div>
            <p>Hop on a call to see if your company is a good fit for Octy AI. No pushy sales calls, we promise.</p>
            <Link to="/book-a-call" className="theme-btn-one">
              Book a call 🤙
            </Link>
          </div>
        </div>
      </div>
      {/* <!-- /.container --> */}
      <img
        src="images/shape/239.svg"
        alt="shape"
        className="shapes shape-one"
      />
      <img
        src="images/shape/240.svg"
        alt="shape"
        className="shapes shape-two"
      />
      <img
        src="images/shape/232.svg"
        alt="shape"
        className="shapes shape-three"
      />
      <img
        src="images/shape/233.svg"
        alt="shape"
        className="shapes shape-four"
      />
      <img
        src="images/shape/234.svg"
        alt="shape"
        className="shapes shape-five"
      />
      <img
        src="images/shape/235.svg"
        alt="shape"
        className="shapes shape-six"
      />
      <img
        src="images/shape/236.svg"
        alt="shape"
        className="shapes shape-seven"
      />
      <img
        src="images/shape/232.svg"
        alt="shape"
        className="shapes shape-eight"
      />
    </div>
  );
};

export default CallToAction;
