// COUNTS

/**
 * Calculate the number of expected customers, for one year, 
 * based on current customer count, monthly acquisitions and monthly churn rate.
 */
// TODO: This calculation is wrong!
/*
 	def calc(n, s, churn):
		end = ((churn/100)*s) + (n*12)
		return end
	
	print(calc(2000, 7000, 40))

	Determine where this is being called and amend accordingly
	Need to establish the monthly figures, not just the projected annual
*/
 function CalculateCustomerCount(currentCustomers, monthlyAcquisitions, monthlyChurnRate){
    let customerCount = [];
    let monthTotalCustomers = (currentCustomers + monthlyAcquisitions) - 
        (((currentCustomers + monthlyAcquisitions)/100) * monthlyChurnRate);
    for (let i = 0; i < 12; i++) {
        customerCount.push(monthTotalCustomers);
        monthTotalCustomers = (monthTotalCustomers + monthlyAcquisitions) - 
            ((monthTotalCustomers/100) * (monthlyChurnRate)); 
    }
    return customerCount;
  };

/**
 * Calculate the number of event instances for 
 * total number of customers for each month in one year.
 */
function CalculateMonthlyEventCount(customers) {
	const eventsPerCustomerSession = 8;
	const averageMonthlySessions = 6;
	let eventCounts = [];
	for (let i = 0; i < customers.length; i++) {
		eventCounts.push(
			(customers[i]*eventsPerCustomerSession)*averageMonthlySessions
		);
	}
	return eventCounts;
}


// FEES

/**
 * Calculate data fees for number of customers and events per month.
 * Fees are based on the provided cost per megabyte unit (costPerMB) and 
 * the number of worker jobs per month (monthlyWorkerJobs)
 */
function CalulateMBUsageFees(customers, events, costPerMB, monthlyWorkerJobs) {
	const objectsToMB = 6500; //NOTE: 6.5k objects ~ 1 Megabyte
	let monthlyMBFees = [];
	for (let i = 0; i < 12; i++) {
		let totalMBfee = ((customers[i] + events[i]) / objectsToMB) * costPerMB;
		monthlyMBFees.push(
			totalMBfee * monthlyWorkerJobs
		);
	}
	return monthlyMBFees;
}


/**
 * Calculate compute fees for specified cost per compute unit 
 * and the number of monthly worker jobs
 */
function CalulateComputeUsageFees(costPerCompute, monthlyWorkerJobs) {
	return costPerCompute*monthlyWorkerJobs;
}


/**
 * Calculate the average aggregate fees associated with compute and data usage.
 * Return the average monthly fee associated with data usage and 
 * the average monthly fee for data usage and compute usage combined.
 */
function CalculateAverageMonthlyMeteredFees(monthlyComputeFees, monthlyMBFees){
	let totalMBfees = 0;
	for (let i = 0; i < 12; i++) {
		totalMBfees += monthlyMBFees[i];
	}
	let averageMonthlyMBFee = totalMBfees / 12;
	return [averageMonthlyMBFee, averageMonthlyMBFee + monthlyComputeFees];
}


// RETURNS

/**
 * Calculate the expected revenue increase Octy produces in one year.
 * Returns monetary difference and percentage difference
 */
function CalculateRevenueIncrease(customersWithoutOcty, customersWithOcty, arpu) {
	function calculateRevenue(customers) {
		let revenue = 0;
		for (let i = 0; i < 12; i++) {
			revenue += (customers[i] * arpu)
	  }
		return revenue;
	}

	let revenueWithOcty = calculateRevenue(customersWithOcty);
	let revenueWithoutOcty = calculateRevenue(customersWithoutOcty);
	let monetaryDifference = revenueWithOcty - revenueWithoutOcty;
	let percentageDifference = (monetaryDifference / revenueWithoutOcty) * 100;
	return [revenueWithOcty, revenueWithoutOcty, monetaryDifference, percentageDifference];
}

/**
 * Calculate prospects potential return on investment
 */
function CalculateROI(totalCosts, revenueIncrease) {
	return revenueIncrease - totalCosts;
}

export { CalculateCustomerCount, 
    CalculateMonthlyEventCount, 
    CalulateMBUsageFees, 
    CalulateComputeUsageFees, 
    CalculateAverageMonthlyMeteredFees, 
    CalculateRevenueIncrease, 
    CalculateROI };
