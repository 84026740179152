import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';


const changelogItems = [

    {
        colour : "mark-blue",
        type : "☼ Imporovement ☼",
        application : "Octy CLI",
        version : "v1.0.1-beta",
        release_notes : `
• Increased semaphore limit to speed up file uploads and prevent them from failing.<br />
• Resolved typo in churn report generated markdown files.<br />
`,
        resources : "• https://github.com/Octy-ai/octy-cli/releases/tag/v1.0.1-beta"
    },
    {
        colour : "mark-green",
        type : "☆ New Feature ☆",
        application : "Octy CLI",
        version : "v1.0.0-beta",
        release_notes : `
• Initial Public BETA release<br />
`,
        resources : "• https://github.com/Octy-ai/octy-cli/releases/tag/v1.0.0-beta"
    },
    {
        colour : "mark-green",
        type : "☆ New Feature ☆",
        application : "Octy API",
        version : "v1-beta",
        release_notes : `
• Initial Public BETA release<br />
`,
        resources : "• --"
    },

];


function Items({ currentItems }) {
  return (
    <>
      {currentItems &&
        currentItems.map((item) => (
        //   <div>
        //     <h3>Item #{item}</h3>
        //   </div>
            <div>
            <h5 className="font-source-code-pro mb-20">
                Release Date: <mark>21 Jan 2022</mark>
            </h5>
            <div className={item.colour}>
                <pre>
                {item.type} <br /> 
                Application : {item.application} <br /> 
                Version : {item.version} <br /> 
                ================================== <br/>
                Release notes: <br/>
                {item.release_notes}
                ================================== <br/>
                Resources: <br/>
                {item.resources}
                </pre>
            </div>
            </div>
                        
        ))}
    </>
  );
}



function ChangeLogItems({ itemsPerPage }) {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(changelogItems.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(changelogItems.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % changelogItems.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    window.scrollTo(0, 500);
  };

  return (
    <>
      <Items currentItems={currentItems}/>
      <ReactPaginate
        previousLabel="previous"
        nextLabel="next"
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        className="pagination" 
        activeClassName="active"
        containerClassName="pagination justify-content-center"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
      />
    </>
  );
}

export default ChangeLogItems;