import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import ChangeLogItems from "../../../components/changelog/ChangeLog"
import CallToAction from "../../../components/call-to-action/CallToAction";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";

const ChangelogPage = () => {
  return (
    <>
      <Helmet>
        <title>Changelog || Octy AI</title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header />
      {/* End Header */}

      {/* =============================================
				Hero Banner
			============================================== */}
      <div className="hero-banner-twelve">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-8 col-sm-10 pb-150">
                <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
                Product updates
                </h1>
                <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
                >
                    If you are a data-driven team who is seeking to take advantage of the power of AI to drive your business growth without the costly need for external consultants, you’ve found the right platform.
                </p>
              </div>
            </div>
                <img
                src="images/shape/233.svg"
                alt="shape"
                className="shapes shape-four"
                />
                <img
                src="images/shape/234.svg"
                alt="shape"
                className="shapes shape-five"
                />
                <img
                src="images/shape/235.svg"
                alt="shape"
                className="shapes shape-six"
                />
                <img
                src="images/shape/236.svg"
                alt="shape"
                className="shapes shape-seven"
                />
                <img
                src="images/shape/232.svg"
                alt="shape"
                className="shapes shape-eight"
                />
          </div>
        </div>
      </div>
      {/* End Hero banner */}

      {/* =============================================
        Changelog items
      ==============================================  */}
      <div className="doc-container mt-70 sm-m0">
        <div className="container">
          <div className="row flex-xl-nowrap no-gutters">
            {/* <!-- ****************************** DOC MAIN BODY ********************************* --> */}
            <main className="col-12 doc-main-body">
              <ChangeLogItems itemsPerPage={8}/>
            </main>
          </div>
        </div>
      </div>
      {/* End Changelog items  */}

      {/* =====================================================
           CTA
      ===================================================== */}
      <CallToAction />
      {/* End CTA */}

      {/* =====================================================
        Footer 
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}
      
    </>
  );
};

export default ChangelogPage;
