import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import TeamDetailsSlider from "../../../../components/team/TeamDetailsSlider";
import CallToAction from "../../../../components/call-to-action/CallToAction";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";

const AboutPage = () => {
  return (
    <div className="main-page-wrapper p0">

      <Helmet>
        <title>
          About || Octy AI
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header/>
      {/* End Header */}

      {/* =============================================
				Hero banner
			============================================== */}
      <div className="hero-banner-twelve">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row pb-100">
              <div className="col-lg-7 col-md-8 col-sm-10 pb-20">
                <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
                    Who we are
                </h1>
                <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                We embrace change and new ideas.
Innovation is the lifeblood of our business. We’re constantly exploring, learning, and evolving so that we can bring the most cutting-edge AI solutions to our customers. We know that meaningful change doesn’t happen by staying comfortable or playing it safe.
This doesn't mean being reckless. We take risks based on data and a deep understanding of what's possible. Our aim is to make Octy AI the acme of artificial intelligence. — Henning Dekant.
                </p>
              </div>
              <div className="col-lg-5 col-md-4">
                <img
                  src="images/logo/light_text_under_trans@2x.png"
                  alt="Octy AI logo"
                  className="img-meta"
                />
              </div>
            </div>
            <img
              src="images/shape/233.svg"
              alt="shape"
              className="shapes shape-four"
              />
              <img
              src="images/shape/234.svg"
              alt="shape"
              className="shapes shape-five"
              />
              <img
              src="images/shape/235.svg"
              alt="shape"
              className="shapes shape-six"
              />
              <img
              src="images/shape/236.svg"
              alt="shape"
              className="shapes shape-seven"
              />
              <img
              src="images/shape/232.svg"
              alt="shape"
              className="shapes shape-eight"
              />
          </div>
        </div>
      </div>
      {/* End hero banner */}

      {/* 	=====================================================
				Team Details
			===================================================== */}
      <div className="team-details mb-50 md-mb-10">
        <div className="container position-relative arrow-middle-center">
          <TeamDetailsSlider />
          {/* Team Details Gallery */}
        </div>
        <img
          src="images/shape/214.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/215.svg"
          alt="shape"
          className="shapes shape-two"
        />
      </div>
      {/* End Team Details */}

      {/* =====================================================
           CTA
      ===================================================== */}
      <CallToAction />
      {/* End CTA */}

      {/* =====================================================
        Footer
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer/>
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}

    </div>
  );
};

export default AboutPage;
