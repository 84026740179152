import React from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value} %`;
}

const marks = [
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
    label: '60%',
  },
  {
    value: 70,
    label: '70%',
  },
  {
    value: 80,
    label: '80%',
  },  {
    value: 90,
    label: '90%',
  },
];

const ChurnRate = ({ churnRate, onChurnRateChange }) => {

  const handleChurnRateInput = (event) => {
    onChurnRateChange(event.target.value);
  };
  return (
    <>
      <label htmlFor="cr">Annual Churn Rate %</label>
      <br/>
      {/* <input
        autoComplete="off"
        className="input"
        type="number"
        id="cr"
        name="cr"
        value={churnRate}
        placeholder="0"
        onChange={handleChurnRateInput}
        required
      /> */}
      <Box sx={{ width: 400 }}>
      <Slider
        aria-label="Small steps"
        defaultValue={30}
        getAriaValueText={valuetext}
        step={10}
        marks={marks}
        min={10}
        max={90}
        valueLabelDisplay="auto"
        onChange={handleChurnRateInput}
      />
    </Box>
    </>
  );
};

export default ChurnRate;