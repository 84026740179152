import React from "react";

// HOME PAGE ROUTES

import HomeLandingPage from "../views/home/HomeLandingPage";


// All INNER PAGES ROUTES START FROM BELLOW

// PAGES DROPDOWN ALL ROUTES

// Contact us inner page
import Contact from "../views/inner-pages/pages/contact/Contact";

// About us inner page
import About from "../views/inner-pages/pages/about/About";

// For Marketers
import ForMarketers from "../views/inner-pages/pages/marketers/ForMarketers";

// For Developers
import ForDevelopers from "../views/inner-pages/pages/developers/ForDevelopers";

// Partnership
import Partnership from "../views/inner-pages/pages/partnership/Partnership"

// Pricing inner page
import Pricing from "../views/inner-pages/pages/pricing/Pricing";
import PricingCalculator from "../views/inner-pages/pages/pricing/PricingCalculator";

// DOCS DROPDOWN ALL ROUTES
import Changelog from "../views/inner-pages/docs/Changelog";

// Not Found Page
import NotFound from "../views/NotFound";

// Route Specific
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ScrollTopBehaviour from "../components/ScrollTopBehaviour";

const Routes = () => {
  return (
    <>
      <Router>
        <ScrollTopBehaviour />
        <Switch>
          <Route exact path="/" component={HomeLandingPage} />

          {/* contact us inner page */}
          <Route path="/book-a-call" component={Contact} />

          {/* about us inner page */}
          <Route path="/about" component={About} />

          {/* for marketers inner pages */}
          <Route path="/for-marketers" component={ForMarketers} />
  
          {/* for developers inner pages */}
          <Route path="/for-developers" component={ForDevelopers} />

          {/* partnerships inner pages */}
          <Route path="/partnership" component={Partnership} />

          {/* pricing (with calculator) inner page */}
          <Route path="/2be6a268-0752-4686-ad89-cb45b2cf4bb6" component={PricingCalculator} />

          {/* pricing inner page */}
          <Route path="/pricing" component={Pricing} />


          {/* inner pages Doc   */}
          <Route path="/changelog" component={Changelog} />

          {/* NotFound Route */}
          <Route component={NotFound} />
        </Switch>
      </Router>
    </>
  );
};

export default Routes;
