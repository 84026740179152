import React from "react";
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import HtmlTooltip from "../tooltip/HtmlTooltip";

const PricingContent = [

  {
    packName: "Pro",
    price: "499",
    packageFor: "$475 / month",
    icon: "pro-icon",
    billCycle: "",
    preFeatures: [
      "Recommendations system",
      "Churn prediction analysis",
      "Profile identification",
      "Segmentation Engine",
      "RFM analysis",
      "Message template Engine",
      "50k Profiles",
      "100 items",
      "1M Events per month",
      "50 Event types",
      "50 Segment definitions",
      "100 Message templates",
      "50k API requests /mo",
      "Email + Basic integration support"
    ],
  },
  {
    packName: "Enterprise",
    price: "999",
    packageFor: "$1750/ month",
    icon: "enterprise-icon",
    billCycle: "month",
    preFeatures: [
      "pro +",
      "Exclusive access to future toolchains",
      "Data cleaning",
      "250k Profiles",
      "500 items",
      "10M Events per month",
      "500 Event types",
      "100 Segment definitions",
      "500 Message templates",
      "Unlimited API requests",
      "Dedicated account management"
    ],
  },
];

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div className="col-lg-4 col-md-6" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price font-source-code-pro">
              {/* £{val.price}.<sup>99</sup> */}
            </div>
            <div className="pack-rec font-source-code-pro">{val.packageFor}</div>
            <img
              src={`images/icon/${val.icon}.svg`}
              alt="octy pricing icon"
              className="icon"
            />

            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <React.Fragment>
                  <li key={i}>{list}  <PriceTooltip feature={list} /></li>
                </React.Fragment>
              ))}
            </ul>
            <Link to="/book-a-call" className="theme-btn-four">
              Learn more
            </Link>
            <div className="trial-text font-source-code-pro">
              See if this package is for you
            </div>
          </div>
          {/*  /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

function PriceTooltip(props) {
  const feature = props.feature;
  if (feature.includes("Data fees")) {
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            {"This is the amount charged per Megabyte of profiles, events and items data transferred. Please note that a minimum of 1MB of data will be charged per transfer, regardless of the total number of bytes in the transaction."}
          </React.Fragment>
        }
        > 
           
        <Button>
          <img
            src={`images/icon/info-icon.svg`}
            alt="info icon"
            className="icon-small"
          /> 
        </Button>  
      
      </HtmlTooltip>
    );
  } else if (feature.includes("Compute")) {
    return (
      <HtmlTooltip
        title={
          <React.Fragment>
            {"This is the amount charged per hour when conducting training and analytical jobs. Please note, that a minimum of one hour of compute is charged per job, regardless of the duration of the job."}
          </React.Fragment>
        }
      >

        <Button>
          <img
            src={`images/icon/info-icon.svg`}
            alt="info icon"
            className="icon-small"
          /> 
        </Button>
        
    </HtmlTooltip>
    );
  };
  return <div></div>;
}

export default PricingMonthly;
