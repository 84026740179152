import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import HeroBanner from "../../components/hero-banner/HeroBanner";
import Brands from "../../components/brand/Brands";
import ForMarketersDevelopers from "../../components/for-marketers-developers/ForMarketersDevelopers";
import RetentionToolchainFeatures from "../../components/features/RetentionToolchainFeatures";
import RetentionStatts from "../../components/counter/RetentionStatts";
//import Testimonials from "../../components/testimonial/Testimonials"
import CallToAction from "../../components/call-to-action/CallToAction";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";

const HomeLandingPage = () => {
  return (
    <div className="main-page-wrapper p0 font-source-code-pro">
      
      <Helmet>
        <title>
          Octy AI - Harness the power of your data
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header/>
      {/* End Header */}

      {/* =============================================
				Hero Banner
			============================================== */}
      <HeroBanner />
      {/* End Hero Banner */}

      {/* =============================================
        Integrations
      ==============================================  */}
      <div className="useable-tools-section-three pt-100 pb-80 md-pt-100 md-pb-80 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="title-style-four">
                <h2>
                  <span>
                    Works with
                  </span>
                </h2>
              </div>
              <p className="sub-text">
              Octy AI can integrate with all eCommerce platforms, making it easy to connect your data and AI-driven workflows. Easy to integrate, fully customisable. (no matter your stack)
              </p>
            </div>
            {/* End .col */}
          </div>
        </div>
        {/* /.container */}

        <div
          className="
        logo-wrapper
        d-flex
        flex-wrap
        justify-content-center
        align-items-center
      "
        >
          <Brands />
        </div>
        {/*  /.logo-wrapper */}
      </div>
      {/* End integrations */}

      {/* =============================================
          Featured on Postman
      ==============================================  */}
      <div className="fancy-feature-thirty mt-180 md-mt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-9 m-auto">
              <div className="title-style-eight text-center mb-80 md-mb-60">
                <h2>
                  "Look no further if you want to improve the customer experience and give shoppers personalized product recommendations." - <img src={`images/logo/postman.png`} alt="ecommerce brand logo" /> {" "}
                  <span>
                    <p>Check out the full article <a href="https://blog.postman.com/add-ai-capabilities-to-your-app-with-these-apis/" target="_blank"><mark>here</mark></a></p>
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End of Featured on Postman */}


      {/* =============================================
            For Marketers & Developers
      ==============================================  */}
      <div className="fancy-feature-twentyNine lg-container pt-20 mt-20 md-pt-70"
        id="p-tour">
          <ForMarketersDevelopers/>
      </div>
      {/* End for marketer & developers */}
  
      {/* =============================================
          Features
      ==============================================  */}
      <div className="fancy-feature-thirty mt-180 md-mt-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 col-lg-9 m-auto">
              <div className="title-style-eight text-center mb-80 md-mb-60">
                <div className="upper-title">Features</div>
                <h2>
                  {" "}
                  Real-time 360° customer {" "}
                  <span>
                  intelligence{" "}
                  </span>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <div className="container">
            <RetentionToolchainFeatures />
          </div>
          <img
            src="images/shape/237.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/238.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
      </div>
      {/* End of features */}

      {/* =============================================
        Retention Statts
      ==============================================  */}
      <div className="counter-style-three lg-container mt-130">
        <div className="container">
          <div>
            <RetentionStatts />
          </div>
        </div>
      </div>
      {/* End Retention Statts */}

      {/* =====================================================
           Testimonials
        ===================================================== */}
      {/* <div
        className="client-feedback-slider-six pt-200 md-pt-120"
        id="feedback"
      >
        <div className="inner-container">
          <div className="title-style-thirteen text-center mb-50 md-mb-20">
            <div className="upper-title">TESTimonials</div>
            <h2>
              Client
              <span>
                love us & we
                <img src="images/shape/line-shape-14.svg" alt="line shape" />
              </span>
              love them
            </h2>
          </div>
          <div className="clientSliderSix style-two">
            <TestimonialSeven />
          </div>
        </div>
      </div> */}
      {/* End Testimonials */}

      {/* =====================================================
           CTA
      ===================================================== */}
      <CallToAction />
      {/* End CTA */}

      {/* =====================================================
        Footer
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer/>
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}

    </div>
  );
};

export default HomeLandingPage;
