import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Calculator from "../../../../components/calculator/Calculator"
import Pricing from "../../../../components/pricing/Pricing";
import CustomerSuccess from "../../../../components/customer-success/CustomerSuccess";
import CallToAction from "../../../../components/call-to-action/CallToAction";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";

const PricingCalculatorPage = () => {
  return (
    <>

      <Helmet>
        <title>
          {" "}
          Pricing || Octy AI
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header/>
      {/* End Header */}

      {/* =============================================
				Hero Banner
			============================================== */}
      <div className="pricing-section-one mb-150 md-mb-80">
        <div className="hero-banner-twelve">
          <div className="bg-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-8 col-sm-10 pb-100">
                  <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
                  Affordable AI
                  </h1>
                  <p
                className="hero-sub-heading"
                data-aos="fade-up"
                data-aos-delay="100"
                  >
                    You control your costs with Octy AI’s monthly subscription fee and metered billing model.
                  </p>
                  <div data-aos="fade-up" data-aos-delay="200">
              </div>
              </div>

            </div>
                <img
                src="images/shape/233.svg"
                alt="shape"
                className="shapes shape-four"
                />
                <img
                src="images/shape/234.svg"
                alt="shape"
                className="shapes shape-five"
                />
                <img
                src="images/shape/235.svg"
                alt="shape"
                className="shapes shape-six"
                />
                <img
                src="images/shape/236.svg"
                alt="shape"
                className="shapes shape-seven"
                />
                <img
                src="images/shape/232.svg"
                alt="shape"
                className="shapes shape-eight"
                />
          </div>
        </div>
      </div>
      {/* End Hero banner */}

      {/* 	=============================================
				Calculator
			============================================== */}
        <Calculator/>
      {/* End Calculator */}

      {/* 	=============================================
				Pricing table
			============================================== */}
      <div className="pricing-table-area">
          <img
            src="images/shape/62.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/63.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <div className="container">
            <div className="tab-content">
              <Pricing />
            </div>
          </div>
      </div>
      {/* End Pricing table */}

      </div>

      {/* 	=============================================
				CTA
			============================================== */}
      <div className="fancy-text-block-fifteen">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container">
          <div className="bg-wrapper">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <div className="text-wrapper md-pb-40">
                  {/* <img src="images/icon/retention-icon.svg" alt="iocn" className="icon" /> */}
                  <p className="font-source-code-pro">
                  Save tens of thousands each year on machine learning consultants with the done-for-you platform that keeps your customers coming back.
                  </p>

                </div>
                {/*  /.text-wrapper */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 order-lg-first"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                {/* <img
                  src="images/media/img_40.png"
                  alt="media"
                  className="main-img"
                /> */}
                <img 
                src="images/assets/piggy.png" 
                className="main-img"
                />
              </div>
              {/* End .col */}
            </div>
          </div>
          {/* /.bg-wrapper */}

          <div className="contact-banner mt-100 md-mt-60">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-xl-7 col-lg-8"
                data-aos="fade-right"
                data-aos-duration="600"
              >
                <p className="font-source-code-pro">
                Want to learn how you can increase your customer retention and boost your revenue? 🚀
                </p>
              </div>
              <div
                className="col-xl-4 col-lg-3"
                data-aos="fade-left"
                data-aos-duration="600"
              >
                <Link to="/book-a-call" className="theme-btn-four ml-auto">
                  Book a call
                </Link>
              </div>
            </div>
          </div>
          {/* /.contact-banner */}
        </div>
      </div>
      {/*  CTA */}

      {/* 
      =============================================
				CustomerSuccess
		  ============================================== */}
      <CustomerSuccess/>
      {/* CustomerSuccess */}

      {/* =====================================================
        Retention CTA
      ===================================================== */}
      <CallToAction />
      {/* End Retention CTA */}

      {/* =====================================================
        Footer
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer/>
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}

    </>
  );
};

export default PricingCalculatorPage;
