import React from 'react';
import CustomerServiceItems from "./CustomerServiceItems";

const CustomerSuccess = () => {
  return (
    <div className="fancy-feature-thirty mt-60 md-mt-10">
    <div className="wrapper">
    <div className="container">
      <div className="row">
        <div className="col-lg-6">
          <div className="title-style-one">
            <h6 className="font-source-code-pro">The best customer support</h6>
            <h2>Your success is our mission; we're here to help.</h2>
          </div>
          {/* /.title-style-one */}
        </div>
        {/* End .col */}
        <div className="col-lg-6">
          <div className="md-mt-60">
            <CustomerServiceItems />
          </div>
        </div>
        {/* End .col */}
      </div>

    </div>
      <img
        src="images/shape/237.svg"
        alt="shape"
        className="shapes shape-one"
      />
      <img
        src="images/shape/238.svg"
        alt="shape"
        className="shapes shape-two"
      />
    </div>
    {/* <!-- /.wrapper --> */}
  </div>

  );
};

export default CustomerSuccess;