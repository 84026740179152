import React from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}

const marks = [
  {
    value: 500,
    label: '500',
  },
  {
    value: 50000,
    label: '50k',
  }
];

const NewCustomers = ({ newCustomers, onNewCustomersChange }) => {

  const handleNewCustomersInput = (event) => {
    onNewCustomersChange(event.target.value);
  };
  return (
    <>
      <label htmlFor="nc"># of customer acquisitions (Monthly)</label>
      <br/>
      {/* <input
        autoComplete="off"
        className="input"
        type="number"
        id="nc"
        name="nc"
        value={newCustomers}
        placeholder="0"
        onChange={handleNewCustomersInput}
        required
      />
      <br/> */}
      <Box sx={{ width: 400 }}>
      <Slider
        aria-label="Small steps"
        defaultValue={1500}
        getAriaValueText={valuetext}
        step={500}
        marks={marks}
        min={500}
        max={50000}
        valueLabelDisplay="auto"
        onChange={handleNewCustomersInput}
      />
    </Box>
    </>
  );
};

export default NewCustomers;