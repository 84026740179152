import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../../../components/header/Header";
import CallToAction from "../../../../components/call-to-action/CallToAction";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";

const ForDevelopersPage = () => {
  return (
    <div className="main-page-wrapper p0">

      <Helmet>
        <title>
          For Developers || Octy AI
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
        Header
      ==============================================  */}
      <Header/>
      {/* End Header */}

      {/* =============================================
				Hero Banner
			============================================== */}
      <div className="hero-banner-twelve">
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-10 pb-150">
                <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
                For Developers
                </h1>
                <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
                >
                    Plug-and-play AI tools + Awesome Docs
                </p>
                <div data-aos="fade-up" data-aos-delay="200">
              <Link to="/book-a-call" className="get-start-btn">
                Book a call{" "}
                <i className="fa fa-angle-right ml-1" aria-hidden="true"></i>
              </Link>
            </div>
              </div>
              <div className="col-lg-5 col-md-4">
                <img
                  src="images/assets/developers.png"
                  alt="media"
                  className="img-meta d-none d-md-block"
                />
              </div>
            </div>
                <img
                src="images/shape/233.svg"
                alt="shape"
                className="shapes shape-four"
                />
                <img
                src="images/shape/234.svg"
                alt="shape"
                className="shapes shape-five"
                />
                <img
                src="images/shape/235.svg"
                alt="shape"
                className="shapes shape-six"
                />
                <img
                src="images/shape/236.svg"
                alt="shape"
                className="shapes shape-seven"
                />
                <img
                src="images/shape/232.svg"
                alt="shape"
                className="shapes shape-eight"
                />
          </div>
        </div>
      </div>
      {/* End Hero Banner */}

      {/* =====================================================
          Workflows
      ===================================================== */}
      <div className="fancy-feature-one">
        <div className="container">
            <div className="title-style-two">
                <h2>Build AI workflows your way</h2>
            </div>
            <div className="text-wrapper">
            <p className="font-source-code-pro">Octy AI gives you the ability to embed complex AI tools into your stack with ease. </p>
            </div>
            
          <div className="block-wrapper pt-80">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="600"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/rec.png" style={{width: "60%", height: "100"}}  alt="mobile phone with shoe and lightbulb" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <div className="text-wrapper">
                    {/* <h6>Workflow #1</h6> */}
                    <h3 className="title font-source-code-pro">
                    Embed product recommendations into any sales channel in under 30 minutes!
                    </h3>
                    <p className="font-source-code-pro">
                        
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/AI-pipeline.png" style={{width: "85%", height: "100"}} alt="mobile phone cloud brain data" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="600"
                >
                  <div className="text-wrapper">
                    {/* <h6>Workflow #2</h6> */}
                    <h3 className="title font-source-code-pro">
                    Done for you AI pipeline
                    </h3>
                    <p className="font-source-code-pro">
                    Specify the customer data points to collect and where to collect them.
                    </p>
                    <p className="font-source-code-pro">
                    We take care of the rest:
                    </p>
                    <p className="font-source-code-pro">
                    Automated training data shaping, cleaning and augmentation.
                    </p>
                    <p className="font-source-code-pro">
                    Automated hyper-parameter tuning, model training, model hosting, model predictions and prediction applications.
                    </p>

                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                  {/* #Image */}
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <div className="text-wrapper">
                    {/* <h6>management</h6> */}
                    <h3 className="title font-source-code-pro">
                    A blazingly fast, well documented and affordable API designed to simplify the integration of complex AI toolchains into your stack.
                    </h3>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/cli.png"  style={{width: "85%", height: "100"}} alt="computer command line tool" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="600"
                >
                  <div className="text-wrapper">
                    {/* <h6>Workflow #1</h6> */}
                    <h3 className="title font-source-code-pro">
                    CLI for simple account and resource management
                    </h3>
                    <p className="font-source-code-pro">
                    The Octy CLI is a developer tool to help you manage your integration with Octy AI directly from your terminal. It’s simple to install, works on macOS, Windows, and Linux. 
                    </p>
                    <p className="font-source-code-pro">
                    With the Octy CLI you can:
                    </p>
                    <ul>
                        <li>
                        <p>• Update your account & algorithm configurations</p>
                        </li>
                        <li>
                        <p>• Create raw data resources such as profiles, items and event instances </p>
                        </li>
                        <li>
                        <p>• Create, retrieve, update, or delete Octy AI object definition resources</p>
                        </li>
                        <li>
                        <p>• Generate a churn prediction analysis reports in markdown format</p>
                        </li>
                    </ul>
                    <p className="font-source-code-pro">
                        For more on the CLI, go here → <a href="https://github.com/Octy-ai/octy-cli#readme" target="_blank">Octy-CLI</a>
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto pt-50"
                  data-aos="fade-right"
                  data-aos-duration="600"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/thumbsup.png"  style={{width: "80%", height: "100"}} alt="thumbs up" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="600"
                >
                  <div className="text-wrapper">
                    {/* <h6>Workflow #1</h6> */}
                    <h3 className="title font-source-code-pro">
                    Integrate anywhere!
                    </h3>
                    <p className="font-source-code-pro">
                    Octy is a best-in-class API organized around REST. Our set of AI tools can be easily integrated into any backend infrastructure with ease. 
                    </p>
                    <p className="font-source-code-pro">
                    With website integrations and scripts that consume the Octy API, you can create lightweight and powerful AI-driven customer experiences and workflows.
                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
      </div>
      {/* End Workflows */}

      {/* =====================================================
           CTA
      ===================================================== */}
      <CallToAction />
      {/* End CTA */}

      {/* =====================================================
        Footer
      ===================================================== */}
      <footer className="theme-footer-seven mt-120 md-mt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <Footer/>
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRight />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* End Footer */}

      
    </div>
  );
};

export default ForDevelopersPage;