import React from "react";

const Result = ({
  octySubscriptionFeeOutput,
  perMB,
  perCompute,
  octyDataFeeOutput,
  octyComputeFeeOutput,
  octyTotalFeeOutput,
  churnRateOutput,
  revenueTwelveMonthsWithOctyOutput,
  revenueTwelveMonthsWithoutOctyOutput,
  revenueDifferenceOutput,
  revenueDifferencePercentageOutput,
  octyROIOutput,
  onResetClick,
}) => {
  const handlerReset = () => {
    onResetClick();
  };
  return (
    <>
      <div className="grid">
        <p>
          Octy AI Cost (Monthly Avg)
        </p>
        <p id="octyTotalFeeOutput" className="output">
          {octyTotalFeeOutput}
        </p>
      </div>
      <div style={{"paddingLeft" : 40}}>
        <p style={{"fontSize" : 14}}>
          Breakdown:
        </p>
        <p style={{"fontSize" : 14}}>
          Subscription fee : {octySubscriptionFeeOutput}
        </p>
        <p style={{"fontSize" : 14}}>
          Average Data fees (@£{perMB} per MB): {octyDataFeeOutput}
        </p>
        <p style={{"fontSize" : 14}}>
          Compute fees (@£{perCompute} per hour): {octyComputeFeeOutput}
        </p>
      </div>
      <div className="grid">
        <p>
          Annual Churn rate (Month 12)
        </p>
        <p id="churnRateOutput" className="output">
          {churnRateOutput} % (-6%)
        </p>
      </div>
      <div className="grid">
        <p>
          Revenue difference with Octy (Month 12)
        </p>
        <p id="revenueIncreaseOutput" className="output">
          {revenueDifferencePercentageOutput} %
        </p>
      </div>
      <div style={{"paddingLeft" : 40}}>
        <p style={{"fontSize" : 14}}>
          Breakdown:
        </p>
        <p style={{"fontSize" : 14}}>
          12 months revenue with Octy AI : {revenueTwelveMonthsWithOctyOutput}
        </p>
        <p style={{"fontSize" : 14}}>
          12 months revenue without Octy AI : {revenueTwelveMonthsWithoutOctyOutput}
        </p>
        <p style={{"fontSize" : 14}}>
          Monetary difference : {revenueDifferenceOutput}
        </p>
      </div>
      <div className="grid">
        <p>Octy AI ROI (Month 12)</p>
        <p id="octyROIOutput" className="output">
        <mark>{octyROIOutput}</mark>
        </p>
      </div>
      <div className="grid">
      <i style={{"fontSize" : 12}}>Please note, this calculator is an estimate only. 
    Prices and reults may vary depending on usage and other factors</i>
    </div>
    <div className="grid">
    <i style={{"fontSize" : 12}}>Data entered in to this calculator is not collected or stored.</i>
      </div>
    </>
  );
};

export default Result;
