import React from "react";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';

function valuetext(value) {
  return `${value}`;
}

const marks = [
  {
    value: 1000,
    label: '1k',
  },
  {
    value: 250000,
    label: '250k',
  }
];


const CurrentCustomers = ({ currentCustomers, onCurrentCustomersChange }) => {

  const handleCurrentCustomersInput = (event) => {
    onCurrentCustomersChange(event.target.value);
  };
  return (
    <>
      <label htmlFor="cc">Current # of Customers</label>
      <br/>
      {/* <input
        autoComplete="off"
        className="input"
        type="number"
        id="cc"
        name="cc"
        value={currentCustomers}
        placeholder="0"
        onChange={handleCurrentCustomersInput}
        required
      /> */}
      <Box sx={{ width: 400 }}>
      <Slider
        aria-label="Small steps"
        defaultValue={18000}
        getAriaValueText={valuetext}
        step={1000}
        marks={marks}
        min={1000}
        max={250000}
        valueLabelDisplay="auto"
        onChange={handleCurrentCustomersInput}
      />
    </Box>
    </>
  );
};

export default CurrentCustomers;