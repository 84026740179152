import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const serviceItems = [
  {
    title: "24 hour premium email support",
    desc: `For pro and enterprise customers, we offer extended support via alternative channels.`,
    expand: "a",
  },
  {
    title: "Data cleaning",
    desc: `The aggregation and cleaning of data from multiple sources can be a time consuming process. Our team at Octy is happy to help all of our enterprise customers with this.`,
    expand: "b",
  },
  {
    title: "Integration assistance & troubleshooting",
    desc: `Need help integrating Octy? Check out our help center to see demo integrations and read the docs. We also offer direct support on integrations for pro and enterprise customers.`,
    expand: "c",
  },
  {
    title: "Account & Algorithm configurations",
    desc: `Our Client Success team is hands on — from account setup to training, we build relationships and empower each one of our customers to get the most out of their Octy account. We have developed a proven process for implementing and configuring Octy’s algorithm and account settings so that our clients can be up and running without delay.`,
    expand: "d",
  },
  {
    title: "AI-driven Campaign consultation",
    desc: `Octy offers the freedom you need to design and implement end-to-end processes with a mix and match of our AI tools. If you need assistance with AI-campaign design best practices, we can offer consultancy services as well.`,
    expand: "e",
  },
];

const CustomerServiceItems = () => {
  return (
    <div className="accordion-style-four">
      <div className="faq-wrraper">
        <Accordion preExpanded={["b"]} allowZeroExpanded>
          {serviceItems.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default CustomerServiceItems;
