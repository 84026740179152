import React from "react";
import { Link } from "react-router-dom";

const HeroBanner = () => {
  return (
    <div className="hero-banner-twelve lg-container" id="home">
      <div className="container">
        <div className="row">
          <div className="col-xl-11 col-lg-12 col-md-10 m-auto text-center">
          
            <h1 className="hero-heading font-source-code-pro" data-aos="fade-up">
            Take your{" "}
              <span style={{ color: "#FF006B" }}>customer</span>{" "}
              <span style={{ color: "#FEDC00" }}>retention</span> to the next level
              {" "}
              <img
                src="images/assets/rocket.png"
                alt="space rocket flying"
                style={{ height: 80, width: 85, display: "initial" }}
              />
            </h1>
            <p
              className="hero-sub-heading"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Unlock the power of your data to make better decisions, maximize customer retention, and drive revenue.
            </p>
            <div data-aos="fade-up" data-aos-delay="200">
              <Link to="/book-a-call" className="get-start-btn">
                Book a call{" "}
                <i className="fa fa-angle-right ml-1" aria-hidden="true"></i>
              </Link>
            </div>
          </div>
        </div>

        <div className="screen-holder" data-aos="fade-up">
          <img
            src="images/assets/main.png"
            alt="ecommerce store"
            className="img-meta"
          />
          <img
            src="images/assets/hero-1.png"
            alt="mobile phone instagram"
            className="shapes screen-one"
          />
          <img
            src="images/assets/churn-hero.png"
            alt="talking robot"
            className="shapes screen-two"
          />
          <img
            src="images/shape/230.svg"
            alt="screen"
            className="shapes shape-one"
          /> 
          <img
            src="images/shape/231.svg"
            alt="screen"
            className="shapes shape-two"
          />
        </div>
        <img
          src="images/shape/232.svg"
          alt="shape"
          className="shapes shape-three"
        />
        <img
          src="images/shape/233.svg"
          alt="shape"
          className="shapes shape-four"
        />
        <img
          src="images/shape/234.svg"
          alt="shape"
          className="shapes shape-five"
        />
        <img
          src="images/shape/235.svg"
          alt="shape"
          className="shapes shape-six"
        />
        <img
          src="images/shape/236.svg"
          alt="shape"
          className="shapes shape-seven"
        />
        <img
          src="images/shape/232.svg"
          alt="shape"
          className="shapes shape-eight"
        />
      </div>
    </div>
  
  );
};

export default HeroBanner;
