import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PricingMonthly from "./PricingMonthly";
// import PricingYearly from "./PricingYearly";

const Pricing = () => {
  return (
    <Tabs>
      <h1>
        
      </h1>
      <TabList className="nav nav-tabs pricing-nav-one pricing-custom-nav-one mb-0 pt-10">
        {/* <div className="d-flex flex-wrap justify-content-center mb-1">
          <Tab>Monthly</Tab>
        </div> */}
        {/* <div className="offer-text font-source-code-pro mt-3">
          Save 30% on annual plan
        </div> */}
      </TabList>

      <div className="tab-content-wrpper">
        <TabPanel>
          <PricingMonthly />
        </TabPanel>
        {/* End Pricing Month */}
      </div>
    </Tabs>
  );
};

export default Pricing;
