import React from "react";

const Plans = (props) => {

  const handlePlanInputs = (event) => {
    let button = event.target;
    props.onPlanClick(button.value, button.id);
  };

  return (
    <>
      <button
        onClick={handlePlanInputs}
        className="tenPercent selectedButton"
        id="tenPercentId"
        value="499"
      >
        Pro
      </button>
      <button
        onClick={handlePlanInputs}
        className="fifteenPercent"
        id="fifteenPercentId"
        value="999"
      >
        Enterprise
      </button>
    
    </>
  );
};

export default Plans;
