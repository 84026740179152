import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Octy AI</title>
        <meta property="og:site_name" content="Octy AI" />
        <meta
          property="og:url"
          content="https://octy.ai"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Octy AI: Take your customer retention to the next level"
        />
        <meta
          name="keywords"
          content="AI, ML, artificial intelligence, machine learning, Deep learning, neural network, aiaas , sass, shopify, magento, software company"
        />
        <meta
          name="description"
          content="Octy AI is an AI as a service platform dedicated to redcuing the complexity of intergrating AI in to businesses technology stacks."
        />
        <meta name="description" content="AI as a service" />
      </Helmet>
      {/* End Seo Helmt */}

      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;