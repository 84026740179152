import React, { useEffect, useReducer } from "react";

// import "./App.scss";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import Plans from "./Plans";
import CurrentCustomers from "./CurrentCustomers";
import NewCustomers from "./NewCustomers";
import ChurnRate from "./ChurnRate";
import ARPU from "./ARPU";

import Result from "./Result";

import reducer from "./Reducer";

import HtmlTooltip from "../tooltip/HtmlTooltip";
import Button from '@mui/material/Button';

import { 
  CalculateCustomerCount, 
  CalculateMonthlyEventCount, 
  CalulateMBUsageFees, 
  CalulateComputeUsageFees, 
  CalculateAverageMonthlyMeteredFees, 
  CalculateRevenueIncrease, 
  CalculateROI 
} from "./calculatorFunctions";


const defaultState = {
  showCurrentCustomerLimitError : 0,
  showNewCustomerLimitWarning : 0,
  plan: 499,
  currentCustomers: 12000,
  newCustomers: 800,
  churnRate: 30,
  arpu: 40,
  perMB : 0.03,
  perCompute : 1.34,
  octySubscriptionFeeOutput : 0,
  octyDataFeeOutput : 0,
  octyComputeFeeOutput : 0,
  octyTotalFeeOutput : 0,
  churnRateOutput: 0,
  revenueTwelveMonthsWithOctyOutput: 0,
  revenueTwelveMonthsWithoutOctyOutput: 0,
  revenueDifferenceOutput: 0,
  revenueDifferencePercentageOutput: 0,
  octyROIOutput: 0,
};



const Calculator = () => {
    const [state, dispatch] = useReducer(reducer, defaultState);

    useEffect(() => {
      function count(plan, currentCustomers, monthlyAcquisitions, churnRate, arpu) {
  
        // Hard coded variables
        const octyAnnualChurnRedcution = 6;
        const monthlyWorkerJobs = 120;
  
        let costPerMB = 0;
        let costPerCompute = 0;
  
        if (plan == "99") { // Start Up
          if (parseInt(currentCustomers) > 2500){
            state.showCurrentCustomerLimitError = 1;
          } 
          else {
            state.showCurrentCustomerLimitError = 0;
          };
          if (parseInt(currentCustomers) + (parseInt(monthlyAcquisitions)*12) > 2500){
            state.showNewCustomerLimitWarning = 1;
          }else{
            state.showNewCustomerLimitWarning = 0;
          };
          costPerMB = 0.05;
          costPerCompute = 1.68;
  
        }
        if (plan == "499") { // Pro
          if (parseInt(currentCustomers) > 50000){
            state.showCurrentCustomerLimitError = 1;
          } 
          else {
            state.showCurrentCustomerLimitError = 0;
          };
          if (parseInt(currentCustomers) + (parseInt(monthlyAcquisitions)*12) > 50000){
            state.showNewCustomerLimitWarning = 1;
          }else{
            state.showNewCustomerLimitWarning = 0;
          };
          costPerMB = 0.03;
          costPerCompute = 1.34;
  
        }
        if (plan == "999") { // Enterprise
          if (parseInt(currentCustomers) > 250000){
            state.showCurrentCustomerLimitError = 1;
          } 
          else {
            state.showCurrentCustomerLimitError = 0;
          };
          if (parseInt(currentCustomers) + (parseInt(monthlyAcquisitions)*12) > 250000){
            state.showNewCustomerLimitWarning = 1;
          }else{
            state.showNewCustomerLimitWarning = 0;
          };
          costPerMB = 0.02;
          costPerCompute = 1.12;
  
        }
  
        // COUNTS
  
        // Customers
        // TODO: do not divide by 12
        let customersWithOcty = CalculateCustomerCount(parseInt(currentCustomers), parseInt(monthlyAcquisitions), ((parseInt(churnRate)-octyAnnualChurnRedcution)/12));
        let customersWithoutOcty = CalculateCustomerCount(parseInt(currentCustomers), parseInt(monthlyAcquisitions), (parseInt(churnRate)/12));
  
        // Events
        let totalCustomerEvents = CalculateMonthlyEventCount(customersWithOcty);
        
        // FEES
        let monthlyMBFees = CalulateMBUsageFees(customersWithOcty, totalCustomerEvents, costPerMB, monthlyWorkerJobs);
        let monthlyComputeFees = CalulateComputeUsageFees(costPerCompute, monthlyWorkerJobs);
  
        let [avgMonthlyUsageMBFee, avgMonthlyUsageCombinedFee] = CalculateAverageMonthlyMeteredFees(monthlyComputeFees, monthlyMBFees);
  
        // RETURNS
        let [revenueWithOcty, revenueWithoutOcty, monetaryDifference, percentageDifference] = CalculateRevenueIncrease(customersWithoutOcty, customersWithOcty, parseInt(arpu));
        let ROI = CalculateROI((avgMonthlyUsageCombinedFee+parseInt(plan))*12, monetaryDifference);
  

        dispatch({
          type: "SET_PER_MB",
          payload: costPerMB,
        });

        dispatch({
          type: "SET_PER_COMPUTE",
          payload: costPerCompute,
        });

        dispatch({
          type: "SET_OCTY_TOTAL_FEE_OUTPUT",
          payload: formatGBP(avgMonthlyUsageCombinedFee+parseInt(plan)),
        });
  
        dispatch({
          type: "SET_OCTY_SUB_FEE_OUTPUT",
          payload: formatGBP(parseInt(plan)),
        });
  
        dispatch({
          type: "SET_OCTY_DATA_FEE_OUTPUT",
          payload: formatGBP(avgMonthlyUsageMBFee),
        });
  
        dispatch({
          type: "SET_OCTY_COMPUTE_FEE_OUTPUT",
          payload: formatGBP(monthlyComputeFees),
        });
  
        dispatch({
          type: "SET_CHURN_RATE_OUTPUT",
          payload: parseInt(churnRate) - 6,
        });
  
        dispatch({
          type: "SET_REVENUE_WITH_OCTY_OUTPUT",
          payload: formatGBP(revenueWithOcty),
        });
  
        dispatch({
          type: "SET_REVENUE_WITHOUT_OCTY_OUTPUT",
          payload: formatGBP(revenueWithoutOcty),
        });
  
        dispatch({
          type: "SET_REVENUE_DIFF_OUTPUT",
          payload: formatGBP(monetaryDifference),
        });
  
        dispatch({
          type: "SET_REVENUE_DIFF_PER_OUTPUT",
          payload: (Math.round(percentageDifference * 100) / 100),
        });
  
        dispatch({
          type: "SET_OCTY_ROI_OUTPUT",
          payload: formatGBP(ROI),
        });
  
      }
  
      count(state.plan, state.currentCustomers, state.newCustomers, state.churnRate, state.arpu);
    }, [state.plan, state.currentCustomers, state.newCustomers, state.churnRate, state.arpu]);
  
  
    const formatGBP = (numbers) => {
      let number = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
      }).format(numbers);
      return number;
    };
  
    const handleCurrentCustomersChange = (data) => {
      dispatch({ type: "SET_CURRRENT_CUSTOMERS_INPUT", payload: data });
    };
    const handleNewCustomerChange = (data) => {
      dispatch({ type: "SET_NEW_CUSTOMERS_INPUT", payload: data });
    };
    const handleChurnRateChange = (data) => {
      dispatch({ type: "SET_CHURN_RATE_INPUT", payload: data });
    };
    const handleARPUChange = (data) => {
      dispatch({ type: "SET_ARPU_INPUT", payload: data });
    };
    const handlePlanChange = (data, id) => {
      dispatch({ type: "SET_PLAN_INPUT", payload: data });
      activeClass(id);
    };
    const handleReset = () => {
      state.plan = 499;
      dispatch({ type: "RESET" });
    };
  
    const activeClass = (id) => {
      let elementDelete = document.querySelectorAll(".selectedButton");
  
      if (elementDelete[0] !== undefined) {
        document
          .getElementById(elementDelete[0].id)
          .classList.remove("selectedButton");
      }
  
      let elementAdd = document.getElementById(id);
      elementAdd.classList.add("selectedButton");
    };
    return (
      <div className="fancy-text-block-fifteen">
        {/* <header>
            <h1 id="upper">OctyAI ROI</h1>
            <h1 id="lower">calculator</h1>
        </header> */}

        <main>
            <div className="container_app">
            <div className="container_app_left">

                {/* # Plans */}
                <label htmlFor="button_container">Subscription Plan
                </label>
                <div id="button_container">
                <Plans onPlanClick={handlePlanChange} plan={state.plan} />
                <HtmlTooltip
                    title={
                      <React.Fragment>
                        <p>£{state.plan}.00 per month</p>
                        <p>£{state.perMB} per MB</p>
                        <p>£{state.perCompute} per Hour</p>
                        <p>View plans below</p>
                      </React.Fragment>
                    }
                  > 
                    <Button style={{ width: "5%" }}>
                      <img
                        src={`images/icon/info-icon.svg`}
                        alt="info icon"
                        style={{ width: "60%" }}
                      /> 
                    </Button>  
                  </HtmlTooltip>
                </div>
                <br/>
                {/* # ARPU */}
                <ARPU onARPUChange={handleARPUChange} arpu={state.arpu} />
                <br/>
                <br/>
                {/* # Current Customers */}
                <CurrentCustomers onCurrentCustomersChange={handleCurrentCustomersChange} currentCustomers={state.currentCustomers} />
                <Alert severity="error" style={state.showCurrentCustomerLimitError ? {} : { display: 'none' }}>
                <AlertTitle>Error</AlertTitle>
                    The number of current customers exceeds the allowed limit for the selected subscription plan. Reduce this number or select a higher plan.
                </Alert>
                <br/>
                <br/>
                {/* # New Customers */}
                <NewCustomers onNewCustomersChange={handleNewCustomerChange} newCustomers={state.newCustomers} />
                <Alert severity="warning" style={state.showNewCustomerLimitWarning ? {} : { display: 'none' }}>
                <AlertTitle>Warning</AlertTitle>
                The projected number of new customers per month will exceed the allowed limit for the selected subscription plan before 12 months. Reduce this number or select a higher plan.
                </Alert>
                <br/>
                <br/>
                {/* # Churn rate */}
                <ChurnRate onChurnRateChange={handleChurnRateChange} churnRate={state.churnRate} />
                <br/>
                <br/>
                {/* # Notes */}
                <div>
                <p style={{"fontSize" : 14}}>
                    This calculator assumes a few things:
                </p>
                <ul style={{"fontSize" : 12}}>
                    <li>
                    • Your systems will capture 8 different event instances per customer session.
                    </li>
                    <li>
                    • Each customer will have on average six sessions per month.
                    </li>
                    <li>
                    • An average of 120 model training and worker processes will occur each month. * This is dependant on the number of past segment defintions associated with you account.
                    </li>
                    <li>
                    • Octy AI will reduce your annual churn rate by only 6% - this is a pessimistic assumption.
                    </li>
                    <li>
                    • The calculator does not account for the increase in customer referral customer acquisitions that will result from an increase in customer retention.
                    </li>
                </ul>
                </div>

            </div>

            <div className="container_app_right">
                <Result
                onResetClick={handleReset}
                perMB ={state.perMB}
                perCompute ={state.perCompute}
                octySubscriptionFeeOutput={state.octySubscriptionFeeOutput}
                octyDataFeeOutput={state.octyDataFeeOutput}
                octyComputeFeeOutput={state.octyComputeFeeOutput}
                octyTotalFeeOutput={state.octyTotalFeeOutput}
                churnRateOutput={state.churnRateOutput}
                revenueTwelveMonthsWithOctyOutput={state.revenueTwelveMonthsWithOctyOutput}
                revenueTwelveMonthsWithoutOctyOutput={state.revenueTwelveMonthsWithoutOctyOutput}
                revenueDifferenceOutput={state.revenueDifferenceOutput}
                revenueDifferencePercentageOutput={state.revenueDifferencePercentageOutput}
                octyROIOutput={state.octyROIOutput}
                />
            </div>
            </div>
        </main>
      </div>
    );
  };
  
  export default Calculator;